
import Vue from "vue";
import KMenu, { MenuItem } from "@/components/KMenu.vue";

export default Vue.extend({
  name: "TheAuthorisedMenu",
  components: { KMenu },
  computed: {
    portalUrl() {
      return process.env.VUE_APP_PORTAL_URL;
    },
    menuItems(): MenuItem[] {
      return [
        {
          icon: "$log",
          title: this.$t("routes.conversion.index") as string,
          route: { name: "conversion.index" },
        },
        {
          icon: "$addLog",
          title: this.$t("routes.conversion.create") as string,
          route: { name: "conversion.create" },
        },
      ];
    },
  },
});
