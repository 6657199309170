
import Vue from "vue";
import TheAuthorisedAppBar from "@/templates/Authorised/TheAuthorisedAppBar.vue";
import TheAuthorisedMenu from "@/templates/Authorised/TheAuthorisedMenu.vue";

interface ComponentData {
  menuIsOpen: boolean;
}

export default Vue.extend({
  name: "Authorised",
  components: {
    TheAuthorisedMenu,
    TheAuthorisedAppBar,
  },
  data: (): ComponentData => ({
    menuIsOpen: true,
  }),
});
