
import Vue from "vue";
import { UserLevel } from "@/application/enum/UserLevel";

export interface MenuItem {
  title: string;
  icon?: string;
  route?: {
    name: string;
  };
  children?: MenuItem[];
}

export default Vue.extend({
  name: "KMenu",
  props: {
    menuItems: {
      type: Array,
      default: () => [] as MenuItem[],
    },
  },
  methods: {
    handleNavigationClick(item: MenuItem): void {
      if (item.children && item.children.length) {
        this.$emit("openSubMenu", item);
      }
    },
  },
});
