
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import AppBarMenu from "@/templates/Authorised/AppBarMenu.vue";

export default Vue.extend({
  name: "TheAuthorisedAppBar",
  components: { AppBarMenu },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      profile: "authorisation/profile",
    }),
  },
  methods: {
    ...mapActions({
      logout: "authorisation/logout",
    }),
    async handleLogout() {
      await this.logout();
      window.location.reload();
    },
  },
});
