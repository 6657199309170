
import Vue from "vue";
import { mapActions } from "vuex";

export default Vue.extend({
  name: "AppBarMenu",
  computed: {
    portalUrl() {
      return process.env.VUE_APP_PORTAL_URL;
    },
  },
  methods: {
    ...mapActions({
      logout: "authorisation/logout",
    }),
  },
});
